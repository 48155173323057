import { AfterViewInit, Component, OnInit } from '@angular/core';
import { generateClient } from 'aws-amplify/api';
import { listSettings, listSystemCodes } from '../graphql/queries';
import { DataService } from './shared/service/data.service';
import { AuthService } from './shared/service/auth.service';
import { SettingDto } from './shared/dto/dto';

const client = generateClient();
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less',
})
export class AppComponent implements OnInit {
  load: boolean = true;
  constructor(
    private _dataService: DataService,
    private _authService: AuthService
  ) {}
  ngOnInit(): void {
    this.getSystemCodes();
  }

  async getSystemCodes() {
    this.load = true;

    const systemCodesResult = await client.graphql({
      query: listSystemCodes,
    });
    const settingsResult = await client.graphql({
      query: listSettings,
    });
    this._dataService.setSystemCodes(
      systemCodesResult.data.listSystemCodes.items.map((item) => ({
        id: item.id,
        entity: item.entity,
        value: item.value,
        field: item.field,
        label: item.label,
      }))
    );
    const setting = settingsResult.data.listSettings.items[0] as SettingDto;
    this._dataService.setSetting(setting);
    this.load = false;
  }
}

import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { map, tap, catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { Observable, of } from 'rxjs';
import { UserService } from '../../core/users/services/user.service';
import { UserDto } from '../../core/users/dto/dto';
import { environment } from '../../../environments/environment.development';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private userService: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const systemCode = environment.userActiveId;
    return this.auth
      .isAuthenticated()
      .pipe(switchMap((resp) => this.userService.getUserByEmail(resp)))
      .pipe(
        tap((resp: UserDto) => {
          this.auth.user = resp;
        }),
        map((res) => {
          if (!this.auth.user) {
            this.router.navigate(['sign-in']);
          }

          if (
            !this.auth.user.isAdmin ||
            this.auth.user.userSystemCodeId !== systemCode
          ) {
            this.router.navigate(['sign-in']);
          }
          return (
            this.auth.user.isAdmin &&
            this.auth.user.userSystemCodeId === systemCode
          );
        }),
        catchError((err) => {
          this.router.navigate(['sign-in']);
          return of(false);
        })
      );
  }

  canLoad(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const systemCode = environment.userActiveId;
    return this.auth
      .isAuthenticated()
      .pipe(switchMap((resp) => this.userService.getUserByEmail(resp)))
      .pipe(
        tap((resp: UserDto) => {
          this.auth.user = resp;
        }),
        map((res) => {
          if (!this.auth.user) {
            this.router.navigate(['sign-in']);
          }

          if (
            !this.auth.user.isAdmin ||
            this.auth.user.userSystemCodeId !== systemCode
          ) {
            this.router.navigate(['sign-in']);
          }
          return (
            this.auth.user.isAdmin &&
            this.auth.user.userSystemCodeId === systemCode
          );
        }),
        catchError((err) => {
          this.router.navigate(['sign-in']);
          return of(false);
        })
      );
  }
}

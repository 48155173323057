import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./core/auth/sign-in/sign-in.module').then((c) => c.SignInModule),
  },
  {
    path: 'survey/:quoteId',
    loadChildren: () =>
      import('./core/quotes/components/survey/survey.module').then(
        (c) => c.SurveyModule
      ),
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./core/main/main.module').then((m) => m.MainModule),
    canLoad: [AuthGuard],
  },
  { path: '**', redirectTo: 'sign-in' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
